import styled from 'styled-components'

import { theme } from '@/styles/theme'

type SectionTitleProps = {
  titleText: string
  subTitleText?: string
  color: 'normal' | 'white'
  as?: keyof JSX.IntrinsicElements
}

export const SectionTitle = ({
  titleText,
  subTitleText,
  color,
  as = 'h3',
}: SectionTitleProps) => {
  return (
    <SectionTitleDiv>
      <TitleText color={color} as={as}>
        {titleText}
      </TitleText>
      <SubTitleText color={color}>{subTitleText}</SubTitleText>
    </SectionTitleDiv>
  )
}

const SectionTitleDiv = styled.div`
  text-align: center;
`
const TitleText = styled.h3<Pick<SectionTitleProps, 'color'>>`
  font-weight: bold;
  font-size: 42px;
  line-height: 50px;
  margin-bottom: 6px;
  color: ${({ color }) =>
    color === 'normal' ? `${theme.colors.trialBlue}` : `white`};
  font-family: ${theme.fontEn};

  @media screen and (max-width: 1047px) {
    display: none;
  }
`
const SubTitleText = styled.p<Pick<SectionTitleProps, 'color'>>`
  font-size: 18px;
  line-height: 26px;
  color: ${({ color }) =>
    color === 'normal' ? `${theme.colors.overlay}` : `white`};

  @media screen and (max-width: 1047px) {
    font-size: 23px;
    line-height: 1.2;
    font-weight: 700;
  }
`
